import React from "react";

const Pasta = ({itemData, urlFor}) => {
  return (
    <section className="menu-section">
        <div className="menu-image-container" style = {
          itemData[0].pastaImage ?
          {backgroundImage: `url('${urlFor(itemData[0].pastaImage[0].pastaImage.asset.url)}')`} :
          null
          }>
        </div>
        <div className="menu-text-container">
          <div className="combo-text-title">
            <h4>Pasta Menu</h4>
            <h5>All Served with Garlic Bread and Dusted with Parmesan Cheese</h5>
          </div>
          <table className="content-table specialty">
            <thead>
              <tr>
                <td>Item</td>
                <td>Price</td>
              </tr>
            </thead>
            <tbody>
              {
                itemData[0].pasta ?
                itemData[0].pasta.map((items, index) => {
                  return(
                    <tr key={index}>
                      <td>
                        {items.title}
                        {
                          items.details ?
                          <p>{items.details}</p> :
                          null
                        }
                      </td>
                      <td>${items.price.toFixed(2)}</td>
                    </tr>
                  )
                }):
                null
              }
            </tbody>
          </table>
          <div className="sauce-flex">
            <div>
              <h6>Pasta Sauce</h6>
              <ul>
              {
                itemData[0].pastaSauce ?
                itemData[0].pastaSauce.map((item, index) => {
                  return (
                    <li key={index}>{item.title}</li>
                  )
                }) :
                null
              }
              </ul>
            </div>
            <div>
              <h6>Pasta Type</h6>
              <ul>
              {
                itemData[0].pastaType ?
                itemData[0].pastaType.map((item, index) => {
                  return (
                    <li key={index}>{item.title}</li>
                  )
                }) :
                null
              }
              </ul>
            </div>
          </div>
        </div>
      </section>
  )
}

export default Pasta;