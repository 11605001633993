import React from "react";

const Specials = ({itemData}) => {
  return (
      <section className="wrapper">
        <div className="menu-text-container">
          <div className="combo-text-title">
            <h4>Specials</h4>
          </div>
          <table className="content-table specialty">
            <thead>
              <tr>
                <td>Item</td>
                <td>Price</td>
              </tr>
            </thead>
            <tbody>
              {
                itemData[0].specials[0] ?
                itemData[0].specials.map((items, index) => {
                  return(
                    <tr key={index}>
                      <td>
                        {items.title}
                        {
                          items.details ?
                          <p>{items.details}</p> :
                          null
                        }
                      </td>
                      <td>${items.price.toFixed(2)}</td>
                    </tr>
                  )
                }):
              <p className="special-text">There are no specials at this time.</p>}
            </tbody>
          </table>  
        </div>
      </section>
  )
}

export default Specials;