import React from "react";
import "react-responsive-carousel/lib/styles/carousel.css";
import { Carousel } from 'react-responsive-carousel';

const Gallery = ({itemData}) => {
  return (
    <div className="gallery">
      <div className="wrapper">
        <h2>Gallery</h2>
        <Carousel 
        autoPlay={true}
        interval={3000}
        centerMode={true}
        centerSlidePercentage={50}
        infiniteLoop={true}
        emulateTouch={true}
        showStatus={false}
        showIndicators={false} >
          {
            itemData[0].galleryImages[0] ?
            itemData[0].galleryImages.map((photo, index) => {
              return(
                <div key={index}>
                  <img src={photo.galleryImages.asset.url} alt={photo.title} />
                </div>
              )
            }) :
            null
          }
        </Carousel>
      </div>
    </div>
  )
}

export default Gallery;