import React from "react";

const Salad = ({itemData, urlFor}) => {
  return (
      <section className="menu-section">
        <div className="menu-text-container">
          <div className="combo-text-title">
            <h4>Salad Menu</h4>
          </div>
          <table className="content-table specialty">
            <thead>
              <tr>
                <td>Item</td>
                <td>Price</td>
              </tr>
            </thead>
            <tbody>
              {
                itemData[0].salad ?
                itemData[0].salad.map((items, index) => {
                  return(
                    <tr key={index}>
                      <td>
                        {items.title}
                        {
                          items.details ?
                          <p>{items.details}</p> :
                          null
                        }
                      </td>
                      <td>${items.price.toFixed(2)}</td>
                    </tr>
                  )
                }):
                null
              }
            </tbody>
          </table>
        </div>
        <div className="menu-image-container" style = {
          itemData[0].saladImage ?
          {backgroundImage: `url('${urlFor(itemData[0].saladImage[0].saladImage.asset.url)}')`} :
          null
          }>
        </div>
      </section>
  )
}

export default Salad;