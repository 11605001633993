import React from "react";
import bottomPhoto from "./assets/features-bottom-img-removebg-preview.png"
import topPhoto from "./assets/features-top-img-removebg-preview.png"

const About = ({itemData}) => {
  return(
    <section className="about" id="about">
      <img src={topPhoto} alt="background" className="top" />
      <div className="wrapper about-text">
        <h2>About Us</h2>
          {
            itemData[0].about[0] ?
            <>
              <h4>{itemData[0].about[0].title}</h4>
              <p>{itemData[0].about[0].text}</p>
            </> :
            null
          }
      </div>
      <img src={bottomPhoto} alt="background" className="bottom"/>
    </section>
  )
}

export default About