import React from "react";
import Combo from "./Combo";
import Pizza from "./Pizza";
import Pasta from "./Pasta";
import Salad from "./Salad";
import Starters from "./Starters";
import Sandwich from "./Sandwich";
import Wings from "./Wings";
import Specials from "./Specials";
import sanityClient from "./client.js";
import imageUrlBuilder from '@sanity/image-url';
import { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Link } from 'react-router-dom';

const Menu = ({itemData}) => {

  const [ isActive, setIsActive ] = useState({
    activeObject: true,
    objects: [
      {id:1, to:'/', name:'Combo'},
      {id:2, to:'/pizza', name:'Pizza'},
      {id:3, to:'/pasta', name:'Pasta'},
      {id:4, to:'/salad', name:'Salad'},
      {id:5, to:'/starters', name:'Starters'},
      {id:6, to:'/sandwich', name:'Sandwich'},
      {id:7, to:'/wings', name:'Chicken Wings'},
    ]
  })

  const toggleActive = (index) => {
    setIsActive({...isActive, activeObject: isActive.objects[index]})
  }

  const toggleActiveStyles = (index) => {
    if(isActive.objects[index] === isActive.activeObject) {
      return "active"
    } else {
      return null
    }
  }

  const builder = imageUrlBuilder(sanityClient);
    
  function urlFor(source) {
    return builder.image(source)
  }

  return (
    <Router>
      <section id="menu">
        <h3>Our Menu</h3>
        <ul className="wrapper menu-router" >
          {
            isActive.objects.map((object, index) => {
              return(
                <li key={index}><Link to={object.to} className={toggleActiveStyles(index)} onClick={() => {toggleActive(index)}}>{object.name}</Link></li>
              )
            })
          }
          {
            itemData[0].specials[0] ?
            <li key={8}><Link to={'/specials'} className={toggleActiveStyles(8)} onClick={() => {toggleActive(8)}}>{'Specials'}</Link></li> :
            null
          }
        </ul>
        <Route
        exact path='/'
        render={ () => <Combo
        itemData={itemData}
        urlFor={urlFor}/>}/>
        <Route
        path='/pizza'
        render={ () => <Pizza
        itemData={itemData}
        urlFor={urlFor}/>}/>
        <Route 
        path='/pasta'
        render={ () => <Pasta
        itemData={itemData}
        urlFor={urlFor}/>}/>
       <Route 
        path='/salad'
        render={ () => <Salad
        itemData={itemData}
        urlFor={urlFor}/>}/>
        <Route 
        path='/starters'
        render={ () => <Starters
        itemData={itemData}
        urlFor={urlFor}/>}/>
        <Route 
        path='/sandwich'
        render={ () => <Sandwich
        itemData={itemData}
        urlFor={urlFor}/>}/>
        <Route
        path='/wings'
        render={ () => <Wings
        itemData={itemData}
        urlFor={urlFor}/>}/>
        <Route
        path='/specials'
        render={ () => <Specials
        itemData={itemData}/>}/>
    

      </section>
        
    </Router>
  )
}

export default Menu;