import React from "react";

const Wings = ({itemData, urlFor}) => {
  return (
      <section className="menu-section">
        <div className="menu-image-container" style = {
          itemData[0].wingImage ?
          {backgroundImage: `url('${urlFor(itemData[0].wingImage[0].wingImage.asset.url)}')`} :
          null
          }>
        </div>
        <div className="menu-text-container">
          <div className="combo-text-title">
            <h4>Wing Menu</h4>
          </div>
          <table className="content-table specialty">
            <thead>
              <tr>
                <td>Item</td>
                <td>Price</td>
              </tr>
            </thead>
            <tbody>
              {
                itemData[0].wings ?
                itemData[0].wings.map((items, index) => {
                  return(
                    <tr key={index}>
                      <td>
                        {items.title}
                        {
                          items.details ?
                          <p>{items.details}</p> :
                          null
                        }
                      </td>
                      <td>${items.price.toFixed(2)}</td>
                    </tr>
                  )
                }):
                null
              }
            </tbody>
          </table>
          <div className="sauce-flex">
            <div>
              <h6>Wing Sauce</h6>
              <ul>
                {
                  itemData[0].wingSauce ?
                  itemData[0].wingSauce.map((item, index) => {
                    return (
                      <li key={index}>{item.title}</li>
                    )
                  }) :
                  null
                }
              </ul>
            </div>
            <div>
              <h6>Dipping Sauce</h6>
              <ul>
                {
                  itemData[0].dippingSauce ?
                  itemData[0].dippingSauce.map((item, index) => {
                    return (
                      <li key={index}>{item.title}</li>
                    )
                  }) :
                  null
                }
              </ul>
            </div>
          </div>
        </div>
      </section>
  )
}

export default Wings;