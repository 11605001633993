import React from "react";

const Pizza = ({itemData,urlFor}) => {
  return (
    <>
      <section className="menu-section">
        {
          itemData[0].BYOP[0] ?
          <>
            <div className="menu-text-container">
              <div className="combo-text-title">
                <h4>Pizza Menu</h4>
                <h5>Build Your Own Pizza</h5>
              </div>
              <table className="content-table">
                <thead>
                  <tr>
                    <td>&nbsp;</td>
                    <td>Small (10")</td>
                    <td>Medium (12")</td>
                    <td>Large (15")</td>
                  </tr>
                </thead>
                <tbody>
                  {
                    itemData[0].BYOP[0] ?
                    itemData[0].BYOP.map((items, index) => {
                      return(
                        <tr key={index}>
                          <td>{items.title}</td>
                          <td>${items.small.toFixed(2)}</td>
                          <td>${items.medium.toFixed(2)}</td>
                          <td>${items.large.toFixed(2)}</td>
                        </tr>
                      )
                    }):
                    null
                  }
                </tbody>
              </table>
              <div className="toppings">
                <div className="topping">
                  <h6>Cheese:</h6>
                  <ul>
                    {
                      itemData[0].cheeseToppings ?
                      itemData[0].cheeseToppings.map((topping, index) => {
                        return(
                          <li key={index}>{topping.toppings}</li>
                        )
                      }) :
                      null
                    }
                  </ul>
                </div>
                <div className="topping">
                  <h6>Meat:</h6>
                  <ul>
                    {
                      itemData[0].meatToppings ?
                      itemData[0].meatToppings.map((topping, index) => {
                        return(
                          <li key={index}>{topping.toppings}</li>
                        )
                      }) :
                      null
                    }
                  </ul>
                </div>
                <div className="topping">
                  <h6>Vegetable:</h6>
                  <ul>
                    {
                      itemData[0].vegToppings ?
                      itemData[0].vegToppings.map((topping, index) => {
                        return(
                          <li key={index}>{topping.toppings}</li>
                        )
                      }) :
                      null
                    }
                  </ul>
                </div>
                <div className="topping">
                  <h6>Sauce:</h6>
                  <ul>
                    {
                      itemData[0].sauceToppings ?
                      itemData[0].sauceToppings.map((topping, index) => {
                        return(
                          <li key={index}>{topping.toppings}</li>
                        )
                      }) :
                      null
                    }
                  </ul>
                </div>
              </div>
            </div>
            
            <div className="menu-image-container" style = {
              itemData[0].BYOPImage ?
              {backgroundImage: `url('${urlFor(itemData[0].BYOPImage[0].BYOPImage.asset.url)}')`} :
              null
              }>
          </div>
        </> :
        null
        }
      </section>

      <section className="wrapper">
        <div className="menu-text-container">
          <div className="combo-text-title">
            <h3>Speciality Menu</h3>
          </div>
          <table className="content-table specialty">
            <thead>
              <tr>
                <td>&nbsp;</td>
                <td>Small (10")</td>
                <td>Medium (12")</td>
                <td>Large (15")</td>
              </tr>
            </thead>
            <tbody>
              {
                itemData[0].specialty ?
                itemData[0].specialty.map((items, index) => {
                  return(
                    <tr key={index}>
                      <td>{items.title}<p>{items.toppings}</p></td>
                      <td>${items.small.toFixed(2)}</td>
                      <td>${items.medium.toFixed(2)}</td>
                      <td>${items.large.toFixed(2)}</td>
                    </tr>
                  )
                }):
                null
              }
            </tbody>
          </table>
        </div>
      </section>
    </>
  )
}

export default Pizza;