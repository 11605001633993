import React from "react";

const Combo = ({itemData, urlFor}) => {

  return (
    <section className="menu-section">
        <div className="menu-image-container" style = {
          itemData[0].comboImage[0] ?
          {backgroundImage: `url('${urlFor(itemData[0].comboImage[0].comboImage.asset.url)}')`} :
          null
          }>
        </div>
        <div className="menu-text-container">
          <div className="combo-text-title">
            <h4>Combo Menu</h4>
            <h5>Get more for less</h5>
          </div>
          <ul className="menu-product-list">
          {
            itemData[0].combos[0] ?
            itemData[0].combos.map((items, index) => {
              return(
                <li key={index} className="menu-product">
                  <div className="menu-product-price">
                    <h6>{items.title}</h6>
                    <div className="menu-divider"></div>
                    <p>${items.price}</p>
                  </div>
                  <div className="menu-product-description">
                    <p>{items.description}</p>
                  </div>
                </li>
              )
            }) :
            null
          }
          </ul>
        </div>
      </section>
  )
}

export default Combo;