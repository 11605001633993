import React from "react";

const Sandwich = ({itemData, urlFor}) => {

  return (
      <section className="menu-section">
        <div className="menu-text-container">
          <div className="combo-text-title">
            <h4>Sandwich Menu</h4>
          </div>
          <table className="content-table specialty">
            <thead>
              <tr>
                <td>Baked Sandwiches</td>
                <td>Price</td>
              </tr>
            </thead>
            <tbody>
              {
                itemData[0].bakedSandwich ?
                itemData[0].bakedSandwich.map((items, index) => {
                  return(
                    <tr key={index}>
                      <td>
                        {items.title}
                        {
                          items.details ?
                          <p>{items.details}</p> :
                          null
                        }
                      </td>
                      <td>${items.price.toFixed(2)}</td>
                    </tr>
                  )
                }):
                null
              }
            </tbody>
          </table>
          <table className="content-table specialty">
            <thead>
              <tr>
                <td>Cold Sandwiches</td>
                <td>Price</td>
              </tr>
            </thead>
            <tbody>
              {
                itemData[0].coldSandwich ?
                itemData[0].coldSandwich.map((items, index) => {
                  return(
                    <tr key={index}>
                      <td>
                        {items.title}
                        {
                          items.details ?
                          <p>{items.details}</p> :
                          null
                        }
                      </td>
                      <td>${items.price.toFixed(2)}</td>
                    </tr>
                  )
                }):
                null
              }
            </tbody>
          </table>
          <div className="sauce-flex">
            <div>
              <h6>Sandwich Sauce</h6>
              <ul>
                {
                  itemData[0].sandwichSauce ?
                  itemData[0].sandwichSauce.map((item, index) => {
                    return (
                      <li key={index}>{item.title}</li>
                    )
                  }) :
                  null
                }
              </ul>
            </div>
          </div>
        </div>
        <div className="menu-image-container" style = {
          itemData[0].sandwichImage ?
          {backgroundImage: `url('${urlFor(itemData[0].sandwichImage[0].sandwichImage.asset.url)}')`} :
          null
          }>
        </div>
      </section>
  )
}

export default Sandwich;