import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/App.css';
import { useState, useEffect } from 'react';
import sanityClient from "./client.js";
import Header from './Header';
import About from './About'
import Menu from './Menu';
import Gallery from './Gallery';
import Footer from './Footer';

function App() {

  const [ itemData, setItemData ] = useState(null)

  useEffect(() => {

    document.title = "Venice Pizza"

    sanityClient.fetch(`{
      "header": *[_type == "header"]  | order(order asc) {text,title,order,headerbackground {
        asset->{
          _id,
          url
        },
        alt
      },},
      "about": *[_type == "about"],
      "combos": *[_type == "combos"] | order(order asc),
      "comboImage": *[_type == "comboImage"]{comboImage{asset->{_id,url},alt,crop,hotspot}},
      "BYOPImage": *[_type == "BYOPImage"]{BYOPImage{asset->{_id,url},alt,crop,hotspot}},
      "BYOP": *[_type == "BYOP"] | order(order asc),
      "cheeseToppings": *[_type == "toppings" && category == 'cheese'] | order(order asc),
      "meatToppings": *[_type == "toppings" && category == 'meat'] | order(order asc),
      "vegToppings": *[_type == "toppings" && category == 'vegetables'] | order(order asc),
      "sauceToppings": *[_type == "toppings" && category == 'sauce'] | order(order asc),
      "specialty": *[_type == "specialty"] | order(order asc),
      "pasta": *[_type == "pasta"] | order(order asc),
      "pastaSauce": *[_type == "pastaSauce"] | order(order asc),
      "pastaType": *[_type == "pastaType"] | order(order asc),
      "pastaImage": *[_type == "pastaImage"]{pastaImage{asset->{_id,url},alt,crop,hotspot}},
      "salad": *[_type == "salad"] | order(order asc),
      "saladImage": *[_type == "saladImage"] | order(order asc){saladImage{asset->{_id,url},alt,crop,hotspot}},
      "starters": *[_type == "starters"] | order(order asc),
      "starterImage": *[_type == "starterImage"]{starterImage{asset->{_id,url},alt,crop,hotspot}},
      "bakedSandwich": *[_type == "sandwiches" && category == 'baked'] | order(order asc),
      "coldSandwich": *[_type == "sandwiches" && category == 'cold'] | order(order asc),
      "sandwichSauce": *[_type == "sandwichSauce"] | order(order asc),
      "sandwichImage": *[_type == "sandwichImage"]{sandwichImage{asset->{_id,url},alt,crop,hotspot}},
      "wings": *[_type == "chickenWings"] | order(order asc),
      "wingImage": *[_type == "wingImage"] | order(order asc){wingImage{asset->{_id,url},alt,crop,hotspot}},
      "wingSauce": *[_type == "wingSauce"] | order(order asc),
      "dippingSauce": *[_type == "dippingSauce"] | order(order asc),
      "specials": *[_type == "specials"] | order(order asc),
      "galleryImages": *[_type == "galleryImages"]{title,galleryImages{asset->{_id,url,title},alt,crop,hotspot}} | order(order asc),
      "hours": *[_type == "hours"] | order(order asc),
    }`)
    .then(data => {
        setItemData([data])
      })
    .catch(console.error)
  }, [])

  useEffect(() => {

    document.title = "Venice Pizza"

    sanityClient.fetch(`*`)
    .then(data => {
      })
    .catch(console.error)
  }, [])

  return (
    <div>
      
        {
          itemData ?
          <>
            <Header 
            itemData={itemData}
            />
            <main>
            <About 
            itemData={itemData}/>
            <Menu 
            itemData={itemData}/>
            <Gallery 
            itemData={itemData} />
            </main>
            <Footer
            itemData={itemData}/>
          </> :
          null
        }
    </div>
  );
}

export default App;
