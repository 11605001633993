import React from "react";

const Footer = ({itemData}) => {
  return (
    <footer id="contact">
      <div className="footerOpacity">
        <div className="wrapper footerStyle">
          <div className="footerSection footerInfo">
            <div className="logoText">
              <h1>Venice Pizza</h1>
              <p>A Taste of Italy</p>
            </div>
            <div>
              <p>Located in Erin for almost 30 years, we deliver the same quality pizza that got us this far, made from fresh ingredients and freshly cut toppings. Come on in to experience "A Taste of Italy".</p>
            </div>
          </div>
          <div className="footerSection footer-hours">
            <h6>Hours of Operation</h6>
            {
              itemData[0].hours[0] ?
              itemData[0].hours.map((hours, index) => {
                return(
                  <ul key={index} className="hours">
                    <li>
                      <p className="day">{hours.day}</p>
                      <p className="hour">{hours.open} - {hours.close}</p>
                    </li>
                  </ul>
                )
              }) :
              null
            }
          </div>
          <div className="footerSection contact">
            <h6>Contact Info</h6>
            <ul>
              <li>Address:</li>
              <li><a href="http://maps.google.com/?q= 9 Main St, Erin, ON N0B 1T0" target="_blank" rel="noreferrer">9 Main St #5, Erin, ON N0B 1T0</a></li>
              <li>Phone Number:</li>
              <li><a href="tel:+1-519-833-0810">519-833-0810</a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;