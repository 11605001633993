import React from "react";
import Carousel from "react-bootstrap/Carousel";
import defaultImage from "./assets/735484a183b3c8c4f206762583cd4aafdebf9f57-6000x4000.jpeg"
import sanityClient from "./client.js";
import imageUrlBuilder from '@sanity/image-url';


const Header = ({itemData}) => {

  const builder = imageUrlBuilder(sanityClient)

  function urlFor(source) {
    return builder.image(source)
  }

  return(
    <header>
      <nav id="home">
        <div className="wrapper logoText">
          <h1>Venice Pizza</h1>
          <p>A Taste of Italy</p>
        </div>
        <div className="wrapper navigation">
          <ul className="navLinks">
            <li><a href="#home">Home</a></li>
            <li><a href="#about">About</a></li>
            <li><a href="#menu">Menu</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </div>
      </nav>
      <Carousel>
        {
          itemData[0].header ?
          itemData[0].header.map((items, index) => {
            return(
                <Carousel.Item key={index} style={
                  items.headerbackground ? 
                  {backgroundImage: `url('${urlFor(items.headerbackground.asset.url)}')`} :
                  {backgroundImage: `url('${defaultImage}')`} }>
                  <Carousel.Caption>
                    <div className="headerText">
                      <div className="headerInfo">
                        <h2>{items.title}</h2>
                        <p>{items.text}</p>
                        <div className="callUs">
                          <p>Call us:</p>
                          <a href="tel:+1-519-833-0810">519-833-0810</a>
                        </div>
                      </div>
                    </div>
                  </Carousel.Caption>
                </Carousel.Item>
            )
          }) :
          null
        }
      </Carousel>
    </header>
  )
}

export default Header;